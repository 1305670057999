import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { InviteFormComponent } from '../invite-form/invite-form.component';
import { TranslateModule } from '@ngx-translate/core';

export interface InviteModalData {
    idPlayer: number;
}

@Component({
    selector: 'app-invite-modal',
    templateUrl: './invite-modal.component.html',
    styleUrls: ['./invite-modal.component.scss'],
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, InviteFormComponent, TranslateModule],
})
export class InviteModalComponent {
    idPlayer: number;

    constructor(@Inject(MAT_DIALOG_DATA) data: InviteModalData) {
        this.idPlayer = data?.idPlayer;
    }
}
