import { Component, OnInit } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { PushNotificationService } from 'core/push-notifications.service';
import { Session } from 'core/session.service';
import { take } from 'rxjs';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { ListCheckItemComponent } from './list-check-item/list-check-item.component';
import { MatButton } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-subscribe-to-push-notification-modal',
    templateUrl: './subscribe-to-push-notification-modal.component.html',
    styleUrls: ['./subscribe-to-push-notification-modal.component.scss'],
    imports: [CdkScrollable, MatDialogContent, ListCheckItemComponent, MatDialogActions, MatButton, MatDialogClose, TranslateModule],
})
export class SubscribeToPushNotificationModalComponent implements OnInit {
    public supportedInBrowser = false;
    public serviceWorkerEnabled = false;
    public permissionGranted = false;
    public subscriptionReceived = false;
    public subscriptionSend = false;

    constructor(
        private readonly swPush: SwPush,
        private readonly session: Session,
        private readonly pushNotificationService: PushNotificationService,
    ) {}

    ngOnInit(): void {
        this.supportedInBrowser = 'Notification' in window;
        this.serviceWorkerEnabled = this.swPush.isEnabled;

        if (!this.supportedInBrowser || !this.serviceWorkerEnabled) {
            return;
        }
        this.session.userInitialized.pipe(take(1)).subscribe(() => {
            Notification.requestPermission()
                .then((permission) => {
                    if (permission === 'granted') {
                        this.permissionGranted = true;
                        return this.swPush.requestSubscription({ serverPublicKey: this.session.user.vapidPublicKey });
                    } else {
                        return Promise.reject(new Error(permission));
                    }
                })
                .then((subscription) => {
                    this.subscriptionReceived = true;
                    return this.pushNotificationService.createSubscription(subscription);
                })
                .then(() => {
                    this.subscriptionSend = true;
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch((err) => {
                    // eslint-disable-next-line no-console
                    console.error('Failed to subscribe', err);
                });
        });
    }
}
