import { Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-list-check-item',
    templateUrl: './list-check-item.component.html',
    styleUrls: ['./list-check-item.component.scss'],
    imports: [NgIf, MatIcon],
})
export class ListCheckItemComponent {
    @Input() text: string;

    @Input() checked = false;
}
