import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { Session } from 'core/session.service';

@Directive({ selector: '[appIfInRole]' })
export class IfInRoleDirective {
    @Input() set appIfInRole(value: string) {
        if (this.session.user.isInRole(value)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly session: Session,
    ) {}
}
